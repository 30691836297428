@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

$common-font: 'Playfair Display', serif;
$heading-font: 'Josefin Sans', sans-serif;
$transition: .5s all ease;
$all-size: 16px;
$white-color: #ffffff;
$grey-color: #d5d7d7;
$gold-color: #c1ab65;
/*-- Home One --*/
$gradient-green: linear-gradient(90deg, rgba(16,195,23,1) 23%, rgba(21,208,154,1) 64%);

/*-- Default CSS --*/
*{
    font-family: "Josefin Sans", sans-serif;
}
body {
    font-family: $common-font;
    color: $grey-color;
    background-color: #0c0c0c;
    font-size: $all-size;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    font-family: $heading-font;
}
p {
    line-height: 1.8;
}
a {
    transition: $transition;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.template-btn{ 
    cursor: pointer;
    font-weight: 600;
    color: #01091e;
    background-color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    padding: 12px 25px 9px;
    position: relative;
    z-index: 1;
    font-family: "Josefin Sans", sans-serif;
    margin-right: 15px;
}

.template-btn:hover{
    color: #fff !important;
}

.template-btn:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    background-color: #c1ab65;
    opacity: 0;
    z-index: -1;
    transition: 0.5s all ease;
    border-radius: 5px;
}

.template-btn:before{
    position: absolute;
    content: "";
    width: 0%;
    height: 0;
    top: 0;
    left: 0;
    background-color: #c1ab65;
    opacity: 0;
    z-index: -1;
    transition: 0.5s all ease;
    border-radius: 5px;
}

.template-btn:hover:before{
    height: 100%;
    width: 100%;
    opacity: 1;
}

.template-btn:hover:after{
    height: 100%;
    width: 100%;
    opacity: 1;
}

.btn-small{
    width: 120px !important;
    font-size: 11px;
}

.btn-black{
    color: #ffffff !important;
    background-color: #000000 !important;
}

.btn-gold{
    color: #ffffff !important;
    background-color: #c1ab65 !important;
}

.btn-gold::after{
    color: #ffffff !important;
    background-color: #000000 !important;
}

.btn-gold::before{
    color: #ffffff !important;
    background-color: #000000 !important;
}

.btn-gold:hover{
    color: #ffffff !important;
    background-color: #000000 !important;
}

.btn-red{
    color: #ffffff !important;
    background-color: #c20303 !important;
}

ngb-modal-backdrop{
    z-index: 999 !important;
}
.modal-body {
    padding: 40px 30px 50px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #000;
    }
    p {
        color: #545555;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .image-area {
        margin-bottom: 10px;

        .col-lg-4 {
            padding-right: 5px;
            margin-right: -5px;
        }
        a {
            display: block;
            margin-bottom: 15px;

            img {
                width: 100%;
            }
        }
    }
    .modal-item {
        margin-bottom: 30px;
    }
    .social-area {
        text-align: center;

        h3 {
            font-size: 20px;
            margin-bottom: 12px;
            font-weight: 600;
            color: #000;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                a {
                    display: block;
                    color: $gold-color;
                    border: 1px solid $gold-color;
                    width: 32px;
                    height: 32px;
                    line-height: 34px;
                    border-radius: 50%;
                    margin-right: 3px;
                    margin-left: 3px;
                    font-size: 16px;
                    text-align: center;

                    &:hover {
                        color: $white-color;
                        background-color: $gold-color;
                    }
                }
            }
        }
    }
}

#myModalRight {
    z-index: 99999;

    .modal-header .close {
        position: relative;
        top: 5px;
        color: #000;
        font-weight: bold;
        font-size: 35px;
    }
}
.modal {
    &.modal-right {
        .modal-dialog {
            max-width: 380px;
            min-height: 100vh;
        }
        &.show .modal-dialog {
            transform: translate(0, 0);
        }
        .modal-content {
            height: 100vh;
            overflow-y: auto;
            border-radius: 0;
        }
    }
    &.modal-left {
        .modal-dialog {
            transform: translate(-100%, 0);
            margin: 0 auto 0 0;
        }
    }
    &.modal-right {
        .modal-dialog {
            transform: translate(100%, 0);
            margin: 0 0 0 auto;
        }
    }
}

.modal-header .close {
    //margin: -1rem auto -1rem -1rem; No se si la plantilla lo utiliza. Si falla descomentar
    float: left;
}

.modal-open .modal{
    color: #000;
}

/*----- Moods ------*/

.display-none { display: none; }
.width-100 { width: 100%; }
.text-align-center { text-align: center; }
.mt20 { margin-top: 20px; }
.background-red { background-color: red !important;}
/*----- End Moods ------*/